/* Default layers for all Integration projects */
@layer reset,
iac_reset,
iac_base,
iac_tokens,
defaults,
styling,
elements,
application,
utilities,
old_console_styles,
iac_recipes,
iac_utilities;

@layer reset {
  :root {
    /**
    * Set Scrollbar css variables
    **/
    --scrollbar-width: 8px;
    --scrollbar-height: 8px;
    --scrollbar-border-radius: 3px;
    --scrollbar-thumb-color: rgba(0, 0, 0, 0.2);
    --scrollbar-track-color: rgba(0, 0, 0, 0.05);
    /**
    * Padding in content sections
    **/
    --padding-x: 2.18rem;
    /* 44px, px-9 */
    --padding-y: 1.5rem;
    /* 24px, py-6 */
  }
}

/* Set default fonts for ParkUI */

:root {
  --global-font-body: Inter, Roboto, 'Helvetica Neue', 'Arial Nova',
    'Nimbus Sans', arial, sans-serif;
  --global-font-mono: 'Space Mono', monospace;
  --fonts-code: var(--global-font-mono);
}

/**
 * Basic REM size
 */

html {
  font-size: 15px;
  font-weight: 400;
}

@media (min-width: 640px) {
  html {
    font-size: 16px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: var(--global-font-body), sans-serif;
}

/**
 * Scrollbar
 */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color, rgba(0, 0, 0, 0.2)) transparent;
}

html.noMacOs {
  .visible-on-mac {
    display: none;
  }
}

html:not(.noMacOs) {
  .visible-not-on-mac {
    display: none;
  }
}

/**
 * Specific styles for body in console app
 */

body {
  font-size: 14px;
  display: block;
  margin: 0;
  padding: 0;
  min-width: 100vw;
  min-height: 100vh;
}

@layer old_console_styles {
  h1 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.4;
  }

  h2 {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    font-size: 1.188rem;
    line-height: 1.2;
    font-weight: 700;
  }

  h3 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.063rem;
    line-height: 1.2;
    font-weight: 700;
  }

  h4 {
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 700;
  }

  p {
    line-height: 1.4;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h4 + p {
    margin-top: -0.5rem;
  }

  p + p {
    padding-top: 0.5rem;
  }

  .link {
    --tw-text-opacity: 1;
    color: rgb(13 121 194 / var(--tw-text-opacity));
  }

  .link:hover {
    text-decoration-line: underline;
  }
}
